import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'its-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() disableLink = false;
  @Input() link = '';
  @Input() linkActive = false;
  @Input() queryParams = {};
  @Input() classes = '';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() color: ThemePalette | string;
  @Input() variant:
    | 'basic'
    | 'raised'
    | 'flat'
    | 'stroked'
    | 'link'
    | 'icon'
    | 'icon-fab'
    | 'mini-fab' = 'flat';
  @Input() @HostBinding('attr.disabled') disabled: boolean;
  @HostBinding('style.pointer-events') get pEvents(): string {
    if (this.disabled) {
      return 'none';
    }
    return 'auto';
  }
}
