import { ICompany, ICompanyResponse } from '@modules/companies/interfaces';
import {
  IResume,
  IResumeResponse
} from '@modules/resumes/interfaces/resume.interfaces';

import { ActiveStatusEnum, LanguageEnum } from '@common/shared/enums';
import { IUserIntegration } from '@common/shared/interfaces';

export interface IForgotPassword {
  username: string;
}

export interface IResetPassword {
  password: string;
}

export interface ISignIn {
  username: string;
  password: string;
}

export interface ISignUp {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  isAgentConfirm?: boolean;
  recaptcha?: string;
}

export interface IPublisher {
  id: number;
  description: string;
  verified: boolean;
  user: IUserEntity;
}

export interface IUserEntity {
  avatar: string;
  firstName: string;
  fullName?: string;
  id: string;
  lastName: string;
  agentSites: string[];
  lastActivity?: Date;
  resumes?: IResume[];
  multiResume?: boolean;
  activeJobs?: number;
  status?: ActiveStatusEnum;
  username?: string;
  lang?: LanguageEnum;
  integrations?: IUserIntegration[];
  publisher?: IPublisher;
  postsCount?: number;
}

export interface IAdminUserEntity extends IUserEntity {
  username: string;
  updated: Date;
  created: Date;
  status: ActiveStatusEnum;
  company: ICompany;
  resumes?: IResume[];
}

export interface IProfileResponse {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  isAdmin: boolean;
  username: string;
  agentSites: string[];
  lang: LanguageEnum;
  lastActivity: Date;
  mailingRecommendation: boolean;
  mailingAppUpdate: boolean;
  resumes: IResumeResponse[];
  company: ICompanyResponse;
  integrations?: IUserIntegration[];
}

export interface IProfile extends IUserEntity {
  fullName: string;
  mobileName: string;
  company: ICompany;
  email?: string;
  isAdmin?: boolean;
  hasResume?: boolean;
  mailingRecommendation?: boolean;
  mailingAppUpdate?: boolean;
  exist?: boolean;
}
