import { CurrencyEnum } from '@common/shared/enums';

import {
  ISelectGroupOption,
  ISelectOption
} from '@client/shared/components/select';

export interface IValues {
  categories?: ISelectOption[];
  categoriesMap?: { [key: number]: string };
  categoriesGroup?: ISelectGroupOption[];
  domains?: ISelectOption[];
  languages?: ISelectOption[];
  currencies?: ISelectOption<CurrencyEnum>[];
  workTypes?: ISelectOption[];
  workTypesTranslate?: { [key: string]: string };
  workTypesGroup?: ISelectGroupOption[];
  english: ISelectOption[];
  englishMap: { [key: number]: string };
  employees: ISelectOption[];
  employeesMap: { [key: number]: string };
  agentRoles: ISelectOption[];
  experiences: ISelectOption[];
  experiencesMap: { [key: number]: string };
  experiencesFrom: ISelectOption[];
  cancelInviteReasons: ISelectOption[];
  cancelInviteReasonsMap: { [key: number]: string };
  closeVacancyReasons: ISelectOption[];
  closeVacancyReasonsMap: { [key: number]: string };
  reqInvState: ISelectOption[];
  reqInvStateMap: { [key: number]: string };
  cancelRequestReasons: ISelectOption[];
  cancelRequestReasonsMap: { [key: number]: string };
  filterInformInterval: ISelectOption[];
  filterInformIntervalMap: { [key: number]: string };
  postStartupState: ISelectOption[];
  postStartupStateMap: { [key: number]: string };
  contactOptions: ISelectOption[];
  modeWork: ISelectOption[];
  hiringSteps: ISelectOption[];
  companyTypes: ISelectOption[];
  stateOptions: ISelectOption[];
  reqInvStatusFilterOptions: ISelectOption[];
  jobsStatusFilterOptions: ISelectOption[];
  invoiceLanguages: ISelectOption<any>[];
  plansNameMap?: { [key: number]: string };
  companyReviewCategories?: ISelectOption[];
  companyReviewCategoriesMap: { [key: number]: string };
  postEventCategories?: ISelectOption[];
  postEventCategoriesMap: { [key: number]: string };
  postNewsCategories?: ISelectOption[];
  postNewsCategoriesMap: { [key: number]: string };
  postStartupCategories?: ISelectOption[];
  postStartupCategoriesMap: { [key: number]: string };
  logicConditions: ISelectOption[];
  logicConditionsMap: { [key: number]: string };
  readyToWork: ISelectOption[];
  readyToWorkMap: { [key: number]: string };
  hubCategories: ISelectOption[];
  hubCategoriesMap: { [key: number]: string };
}
