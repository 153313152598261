import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const ERRORS = [
  _('error.adminPlan.trialNotUsed'),
  _('error.auth.confirmed'),
  _('error.auth.locked'),
  _('error.auth.noAgentData'),
  _('error.auth.notConfirmed'),
  _('error.auth.reset'),
  _('error.auth.robot'),
  _('error.auth.tokenExpiredOrNotValid'),
  _('error.auth.unauthorized'),
  _('error.auth.wrongCredentials'),
  _('error.auth.wrongCurrentPassword'),
  _('error.exist.continuePublish'),
  _('error.exist.jobRequest'),
  _('error.exist.publishJob'),
  _('error.exist.publishPost'),
  _('error.exist.stopPublishJob'),
  _('error.exist.user'),
  _('error.general.offline'),
  _('error.notFound.billing'),
  _('error.notFound.chat'),
  _('error.notFound.company'),
  _('error.notFound.post'),
  _('error.notFound.comment'),
  _('error.notFound.contact'),
  _('error.notFound.parentComment'),
  _('error.notFound.entity'),
  _('error.notFound.event'),
  _('error.notFound.invite'),
  _('error.notFound.invoice'),
  _('error.notFound.job'),
  _('error.notFound.jobRequest'),
  _('error.notFound.jobService'),
  _('error.notFound.noActiveJobsForInvite'),
  _('error.notFound.office'),
  _('error.notFound.planToActivate'),
  _('error.notFound.resume'),
  _('error.notFound.resumeInvite'),
  _('error.notFound.saveRequestState'),
  _('error.notFound.saveInviteState'),
  _('error.notFound.user'),
  _('error.notFound.greenhouseJobId'),
  _('error.notFound.jobId'),
  _('error.notFound.resumeId'),
  _('error.notFound.alreadyInGreenhouse'),
  _('error.permission.addToContact'),
  _('error.permission.agentAlreadyInvited'),
  _('error.permission.agentHasCompany'),
  _('error.permission.applyRequestEmptyResume'),
  _('error.permission.cancelInvoice'),
  _('error.permission.closeNotActiveVacancy'),
  _('error.permission.companyReview'),
  _('error.permission.companyReviewOwner'),
  _('error.permission.deleteActiveJob'),
  _('error.permission.deleteCompActiveJobs'),
  _('error.permission.deleteCompAgents'),
  _('error.permission.deleteResumeWithStats'),
  _('error.permission.denied'),
  _('error.permission.jobAuthorOrCompanyOwner'),
  _('error.permission.noBillingInfo'),
  _('error.permission.oneCompany'),
  _('error.permission.onlyCompanyInvite'),
  _('error.permission.orderCanceled'),
  _('error.permission.orderExpired'),
  _('error.permission.orderPayed'),
  _('error.permission.publishClosed'),
  _('error.permission.removeResumeDataWhenPendingReqInv'),
  _('error.permission.sendInviteToYourSelf'),
  _('error.permission.sendRequestToYourSelf'),
  _('error.permission.stopWorkingOnCampaign'),
  _('error.permission.updateDoneJob'),
  _('error.server.error'),
  _('error.server.general'),
  _('error.server.throttler'),
  _('error.validation.applyJob.englishRequired'),
  _('error.validation.applyJob.locationRequired'),
  _('error.validation.applyJob.experienceRequired'),
  _('error.validation.array.blockedCompanies'),
  _('error.validation.array.categories'),
  _('error.validation.array.domains'),
  _('error.validation.array.languages'),
  _('error.validation.array.types'),
  _('error.validation.array.workTypes'),
  _('error.validation.date.startDate'),
  _('error.validation.date.endDate'),
  _('error.validation.email'),
  _('error.validation.files.onlyImages'),
  _('error.validation.files.onlyPdf'),
  _('error.validation.files.onlyTextPlan'),
  _('error.validation.jobServiceNotFount'),
  _('error.validation.max100.faqQuestion'),
  _('error.validation.max2000.faqAnswer'),
  _('error.validation.max2000.lastExperience'),
  _('error.validation.max2000.resumeDescription'),
  _('error.validation.max2000.reviewMessage'),
  _('error.validation.max2000.comment'),
  _('error.validation.max24.firstName'),
  _('error.validation.max24.lastName'),
  _('error.validation.max255.companyName'),
  _('error.validation.max3.categories'),
  _('error.validation.max5.categories'),
  _('error.validation.max50.title'),
  _('error.validation.max50.name'),
  _('error.validation.max70.filterName'),
  _('error.validation.max70.vacancyTitle'),
  _('error.validation.max80.position'),
  _('error.validation.min200.companyDescription'),
  _('error.validation.min200.lastExperience'),
  _('error.validation.min200.resumeDescription'),
  _('error.validation.min200.vacancyDescription'),
  _('error.validation.min200.vacancyDescription'),
  _('error.validation.min200.projectDescription'),
  _('error.validation.password'),
  _('error.validation.phone'),
  _('error.validation.planNotFount'),
  _('error.validation.required.actAddress'),
  _('error.validation.required.actPostcode'),
  _('error.validation.required.billingAddress'),
  _('error.validation.required.billingCity'),
  _('error.validation.required.billingCountry'),
  _('error.validation.required.billingName'),
  _('error.validation.required.billingPostcode'),
  _('error.validation.required.billingRegion'),
  _('error.validation.required.categories'),
  _('error.validation.required.chatMessage'),
  _('error.validation.required.companyDescription'),
  _('error.validation.required.companyEmployees'),
  _('error.validation.required.companyLogo'),
  _('error.validation.required.companyName'),
  _('error.validation.required.contactValue'),
  _('error.validation.required.dateRange'),
  _('error.validation.required.description'),
  _('error.validation.required.startTime'),
  _('error.validation.required.shortDescription'),
  _('error.validation.required.english'),
  _('error.validation.required.title'),
  _('error.validation.required.name'),
  _('error.validation.required.media'),
  _('error.validation.required.descriptionAbout'),
  _('error.validation.required.hashtag'),
  _('error.validation.required.experience'),
  _('error.validation.required.experienceFrom'),
  _('error.validation.required.faqAnswer'),
  _('error.validation.required.faqQuestion'),
  _('error.validation.required.filterName'),
  _('error.validation.required.firstName'),
  _('error.validation.required.inviteJobs'),
  _('error.validation.required.lastExperience'),
  _('error.validation.required.lastName'),
  _('error.validation.required.location'),
  _('error.validation.required.password'),
  _('error.validation.required.position'),
  _('error.validation.required.recaptcha'),
  _('error.validation.required.reviewMessage'),
  _('error.validation.required.comment'),
  _('error.validation.required.tagValue'),
  _('error.validation.required.vacancyDescription'),
  _('error.validation.required.vacancyTitle'),
  _('error.validation.required.company'),
  _('error.validation.applicationUrl'),
  _('error.validation.url.companySite'),
  _('error.validation.url.linkSite'),
  _('error.validation.url.startupSite'),
  _('error.validation.url.resumeSite'),
  _('error.validation.wrongBudget'),
  _('error.validation.wrongData'),
  _('error.validation.youtubeUrl')
];
