import * as dayjs from 'dayjs';

import { Injectable } from '@angular/core';

import { CompaniesFactory } from '@modules/companies/services';
import { IJob, IJobRequest, IJobResponse } from '@modules/jobs/interfaces';

import { IProfile } from '@core/auth';

import { ENGLISH_NOT_REQUIRED } from '@common/shared/constants';
import { WorkTypeGroupEnum } from '@common/shared/enums';
import { getSalary } from '@common/shared/helpers';

import { COUNTRIES } from '@client/shared/constants';
import { getAvatarPath, getLocationValue } from '@client/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class JobsFactory {
  constructor(readonly companiesFactory: CompaniesFactory) {}
  toRequest(job: IJobRequest): IJobRequest {
    return {
      title: job.title,
      hardSkills: job.hardSkills,
      requirements: job.requirements,
      niceToHave: job.niceToHave,
      responsibilities: job.responsibilities,
      description: job.description || '',
      youtubeVideo: job.youtubeVideo,
      status: job.status,
      categoryConditions: job.categoryConditions,
      workTypes: job.workTypes,
      domains: [job.domains as number].filter(Boolean),
      languages: job.languages,
      offices: job.offices,
      english: job.english || ENGLISH_NOT_REQUIRED,
      englishRequired: job.english ? !!job.englishRequired : false,
      userLocation: job.userLocation,
      userLocationRequired: job.userLocation
        ? !!job.userLocationRequired
        : false,
      experience: job.experience,
      experienceRequired: !!job.experienceRequired,
      salaryFrom: job.salaryFrom,
      salaryTo: job.salaryTo,
      showSalary: job.showSalary,
      bonus: job.bonus > 0 ? +job.bonus : null,
      teamSize: +job.teamSize,
      customerCountry: COUNTRIES[job.customerCountry]
        ? job.customerCountry
        : '',
      customerTrip: job.customerTrip,
      projectDescription: job.projectDescription || '',
      advantages: job.advantages,
      hiringSteps: job.hiringSteps,
      projectStack: job.projectStack,
      questionnaire: job.questionnaire,
      veteran: !!job.veteran,
      applicationUrl: job?.applicationUrl || ''
    };
  }

  fromResponse(job: IJobResponse, profile: IProfile = null): IJob {
    let embed = '';
    if (job?.youtubeVideo) {
      try {
        embed = new URL(job.youtubeVideo).searchParams.get('v');
      } catch (e) {}
    }
    return job
      ? {
          ...job,
          activeDays: job?.published
            ? dayjs().diff(dayjs(job.published), 'day')
            : null,
          company: this.companiesFactory.fromResponse(job?.company),
          salary: getSalary(job.salaryFrom, job.salaryTo),
          companyOwnerOrManager: job?.companyManager || job?.companyOwner,
          isAuthor: job.hasOwnProperty('isAuthor')
            ? job.isAuthor
            : profile?.id === job?.user?.id,
          hiringSteps: job?.hiringSteps ?? {},
          youtubeVideoUrl: embed
            ? `https://www.youtube.com/embed/${embed}`
            : '',
          domainsValue: job?.domains?.map((d) => d.value).join(', '),
          languagesValue: job?.languages?.map((d) => d.value).join(', '),
          userLocationValue: getLocationValue(job.userLocation),
          workTypesMap: job?.workTypes?.length
            ? job?.workTypes?.reduce(
                (acc, el) => {
                  if (acc[el.group]) {
                    acc[el.group].push(el);
                  }
                  return acc;
                },
                {
                  [WorkTypeGroupEnum.Time]: [],
                  [WorkTypeGroupEnum.Place]: []
                }
              )
            : {
                [WorkTypeGroupEnum.Time]: [],
                [WorkTypeGroupEnum.Place]: []
              },
          user: job?.user
            ? {
                ...job.user,
                avatar: getAvatarPath(job?.user?.avatar)
              }
            : null
        }
      : null;
  }
}
